.root {
  display: flex;
  padding: 15px;
  justify-content: center;
  background-color: #00121c;
  height: 100%;
}
.leftBox {
  flex: 2;
}
.rightBox {
  flex: 1;
}

@media screen and (max-width: 800px) {
  .root {
    overflow: auto;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
  }
  .rightBox {
    margin: 20px 0px 20px 0px;
  }
}
