.button {
  background-color: var(--support-color-text);
  color: var(--bg-color-support-comp);
  outline: none;
  box-shadow: none;
  border: 1px solid var(--support-color-text);
  width: 64px;
  height: 32px;
}
.button:active {
  background-color: var(--support-color-text);
  border: 1px solid var(--support-color-text);
}
.backBtn {
  background-color: var(--bg-color-support-comp);
  color: var(--support-color-text);
  outline: none;
  box-shadow: none;
  border: 1px solid var(--support-color-text);
}
.backBtn:active {
  background-color: #353535;
  color: #646464;
  outline: none;
  box-shadow: none;
  border: 1px solid #646464;
}
