#phone-input .react-tel-input .special-label {
  color: #cbcbcb !important;
}

.thirdStepPhoneNumber .ant-input-group-addon {
  color: var(--support-color-text);
  background: var(--bg-color-support-comp);
}

.thirdStepPhoneNumber input {
  background: var(--bg-color-support-comp);
  color: var(--support-color-text);
}

.thirdStepPhoneNumber .ant-input-group-addon {
  color: var(--support-color-text);
  background-color: var(--bg-color-support-comp);
}
.thirdStepPhoneNumber input:hover {
  border-color: var(--support-color-text) !important;
}
