@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
.root {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  border: 1px solid var(--support-color-text);
  cursor: pointer;
}

.root:hover {
  background-color: #07232d;
}

.serviceProviderDetailsContainer > div {
  padding-bottom: 15px;
}
.textColor {
  color: #CBCBCB;
  font-weight: 400;
}
.profileRoot {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
  margin-left: 30px;
  width: 100%;
}
.focusedColor {
  background-color: #07232d;
}
.focusedColor > div,
h3 {
  color: #48bbcb;
}
.profileContainer {
  color: #9999;
  width: 50% !important;
}
.boldName {
  font-family: "Roboto";
  font-weight: bold;
}
.imageContainer {
  display: flex;
  align-items: flex-start;
  position: relative;
}
.imageContainer > img {
  margin: auto;
}
.textContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.schedule {
  display: flex;
  width: 100%;
}
.buttonContainer {
  width: 20%;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
}
.name {
  width: 30%;
}
.day {
  width: 60%;
}
.createRequestContainer {
  border: 1px solid #48bbcb;
  background-color: #07232d;
  outline: none;
  cursor: pointer;
}
.learnMoreContainer {
  border: 1px solid #CBCBCB;
  background-color: #00121c;
  color: #CBCBCB;
  outline: none;
  cursor: pointer;
}
.learnMoreContainer:hover {
  border-color: #42bbcc;
  color: #42bbcc;
}
.createRequestContainer:hover {
  background-color: #42bbcc;
  color: #07232d;
}
@media screen and (max-width: 800px) {
  .root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .profileContainer {
    width: auto !important;
  }
  .imageContainer {
    display: flex;
    height: 20%;
    width: 100%;
  }
  .imageContainer > img {
    margin: auto;
  }
  .textContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  .schedule {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  .buttonContainer {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    width: auto;
  }
  .textContainer > div {
    text-align: center;
  }
  .learnMoreContainer {
    padding: 1em;
  }
}

.serviceProviderRow {
  cursor: pointer;
}

.shopNameColor {
  color: #cbcbcb;
}
