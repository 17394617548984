#summary-root .ant-select-selector {
  border: 1px solid var(--support-color-text) !important;
  background-color: var(--bg-color-support-comp) !important;
  color: var(--bg-color-support-comp);
}

#summary-root .ant-select-selector:hover {
  border: 1px solid var(--support-color-text) !important;
}

#summary-root .ant-select:hover .ant-select-arrow {
  color: var(--support-color-text) !important;
}
#summary-root .ant-select-multiple .ant-select-selection-item {
  background-color: var(--support-color-text);
}

#device-issues_textarea {
  background-color: var(--bg-color-support-comp);
  color: var(--support-color-text);
  border: 1px solid var(--support-color-text) !important;
  outline: none;
}

.ant-select-arrow {
  color: var(--bg-color-support-comp);
}

#issue_type-option {
  background-color: var(--bg-color-support-comp);
  color: var(--support-color-text);
}

#issue_type-option:hover {
  color: var(--bg-color-support-comp);
}

#issue_type-option svg {
  fill: var(--support-color-text) !important;
}

#issue_type-option:hover {
  background-color: var(--support-color-text);
}

#device-issues_textarea:hover {
  border-color: var(--support-color-text) !important;
}
