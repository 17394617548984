.root {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.finish {
  text-align: center;
  color: #757575;
  margin: auto;
  padding: 100px;
}
.button {
  padding: "4px 15px";
  background-color: #42bbcc;
  color: black;
  outline: none;
  box-shadow: none;
  border: 1px solid #42bbcc;
  cursor: pointer;
}
.buttonContainer {
  height: 10%;
  position: relative;
}
@media screen and (max-width: 1024px) {
  /* laptop + tablet */
  .root {
    height: 80%;
  }
}

@media screen and (max-width: 800px) {
  .buttonContainer {
    margin: 75px;
  }
  .step {
    margin: 8px 0px;
  }
  .finish {
    height: 290px;
  }
}
