.mapContainer {
  width: 49%;
  height: 100%;
}

.mapContainerOTSP {
  width: 100%;
  height: 85%;
}

.searchInput {
  width: 40%;
  margin-bottom: 20px;
  background: hsl(0, 0%, 20%);
  color: azure;
  border: 1px solid #222222;
  border-radius: 2px;
  padding: 2px 6px;
  font-size: 14px;
  background-image: url("../../assets/images/searchIcon.png");
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 20px;
  outline: 0;
}

.searchInputOTSP {
  width: 70%;
  margin-bottom: 20px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 4px 11px;
  font-size: 14px;
}

@media screen and (max-width: 800px) {
  .searchInput {
    width: 70% !important;
  }
}
