@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
.root {
  display: flex;
  flex-direction: column;
  background-color: #00121c;
  height: 90vh;
}
.rootMargin {
  margin-left: 50px;
  margin-right: 50px;
}
.background {
  background-color: #00121c;
  height: 100vh;
}
.title {
  font-family: "Roboto";
  color: #48bbcb;
  margin: 16px 0px 5px;
  font-size: 34px;
}
.text {
  font-family: "Roboto";
  color: #cbcbcb;
  margin: 0px 0px 40px;
}
.mapDataContainer {
  display: flex;
  height: 70%;
  width: 100%;
  justify-content: space-around;
}
.searchContainer {
  height: 8%;
  width: 100%;
  z-index: 1;
}
.dataContainer {
  background-color: #333333;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Centeralize {
  align-items: center;
  justify-content: center;
}
.noDataImg {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  z-index: 1;
}
.paperImg {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -60%);
}
.thinkingImg {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(60px, -50px);
}
.dotImg {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background: #bfbfbf;
  position: absolute;
  top: 50%;
  right: 50%;
}
.one {
  transform: translate(41px, -42px);
}
.two {
  transform: translate(48px, -42px);
}
.three {
  transform: translate(55px, -42px);
}
.noDataText {
  position: absolute;
  top: 60%;
  right: 50%;
  transform: translate(50%, 50%);
  font-family: "Roboto";
  color: #999999;
}
.h3 {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #000;
  line-height: 0.1em;
  margin: 10px 0 20px;
  height: 5%;
}
.transformText {
  transform: translate(0, -30px);
}
.spContainer::-webkit-scrollbar {
  width: 5px;
}
.spContainer {
  width: 100%;
  margin-left: 3px;
  overflow-y: auto;
}
.spContainer::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.spContainer::-webkit-scrollbar-thumb {
  background-color: #48bbcb;
  border-radius: 6px;
  border: 1px solid var(--scrollbarBG);
}

@media screen and (max-width: 800px) {
  .rootMargin {
    margin-left: 10px;
    margin-right: 10px;
  }
  .background {
    background-color: #00121c;
    height: 120vh;
  }
  .mapDataContainer {
    display: flex;
    width: 90%;
    height: 110%;
    flex-direction: column;
    align-self: center;
  }
  .root {
    align-items: center;
  }
  .title {
    margin: 16px 0px 5px;
    text-align: center;
  }
  .text {
    text-align: center;
    margin: 0px;
  }
  .mapDataContainer {
    margin-left: 0;
  }
  .mapDataContainer > div {
    width: 100%;
    height: 40%;
  }
}

@media screen and (max-width: 450px) {
  .background {
    height: 130vh;
  }
}
.providerContainer {
  height: 100%;
  width: 50%;
  padding: 0px;
  border-width: 0px;
}

.providerContainerList {
  padding: 1%;
  margin-left: 2%;
  height: 95%;
  overflow-y: scroll;
  scrollbar-color: #00121c #07232d !important;
}

.providerContainerList::-webkit-scrollbar {
  width: 15px;
}

.providerContainerList::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #07232d;
  background: #07232d;
}

.providerContainerList::-webkit-scrollbar-thumb {
  background-color: #00121c;
  box-shadow: inset 0px 0px 3px #07232d;
  min-height: 100px;
}

::-webkit-scrollbar-button {
  background-color: #07232d;
  background-size: 10px 10px;
  background-repeat: no-repeat;
  background-position: center center;
  height: 16px;
  width: 1em;
  box-shadow: inset 1px 1px 2px #07232d;
}

::-webkit-scrollbar-button:end:increment {
  background-image: url("../../assets/images/arrow_down.svg");
}

::-webkit-scrollbar-button:start:decrement {
  background-image: url("../../assets/images/arrow_up.svg");
}
