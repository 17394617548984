.login-button {
  height: 40px;
  border-radius: 2px;
  padding: 8px, 16px, 8px, 16px;
}
body {
  background-color: #e6f1f4 !important;
}
.login-div {
  padding: 10px;
  width: 340px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media (max-height: 500px) {
  .login-div {
    height: 300px !important;
  }
}
.onsor-logo {
  width: 320px;
  margin-bottom: 10%;
}
.header {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #1890ff;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 12px 0px;
}
.login-input {
  margin: 12px 0px;
}
.invalid {
  color: red;
  font-size: 10px;
}
.forgot-password-link {
  float: right;
}
.register-form-card {
  width: 900px;
  overflow: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
