.resetRoot {
  width: 400px;
  margin: 5% auto 0;
  text-align: center;
}
.inputFieldRoot {
  margin-left: 20px;
  margin-top: 100px;
  display: inline-block;
  width: 352px;
  justify-content: center;
}
.passwordResetTitle {
  font-size: 14px;
  line-height: 22px;
  color: #1890ff;
  text-align: left;
  font-weight: bold;
  text-decoration-line: underline;
  text-decoration-color: #1890ff;
}
.passwordResetExplanation {
  margin-top: 28px;
  text-align: start;
  font-weight: bold;
}
.inputField {
  display: block;
  width: 100%;
  margin: 22px 0 auto;
}
.resetButton {
  margin-top: 22px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 16px;
  background: #1890ff;
  border: 1px solid #1890ff;
  box-sizing: border-box;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
  border-radius: 2px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}
.a {
  display: inline-block;
  margin-top: 22px;
}
.informationLogo {
  margin-top: 145px;
}
.checkYourEmail {
  margin-top: 29px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.85);
}
.checkYourEmailSubtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
}
.checkEmailResetButtons {
  text-align: center;
  margin-top: 24px;
}
.checkEmailButton {
  margin-left: 10px;
  margin-right: 10px;
}
.blueButton {
  background: #1890ff;
  color: white;
}
.inputDiv {
  text-align: start;
}
.labelError {
  width: 100%;
  display: inline-flex;
  color: red;
}
