@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
.root {
  width: 100%;
  display: flex;
  background-color: #00121c;
  padding: 10px 30px 10px 30px;
  align-items: center;
  border-bottom: 1px solid #48bbcb;
}
.text {
  margin-left: 1%;
  letter-spacing: 10px;
  color: #cbcbcb;
  font-family: "Roboto";
  font-size: 16px;
}
.logo {
  width: 15%;
  height: auto;
  cursor: pointer;
}
@media screen and (max-width: 800px) {
  .logo {
    width: 50%;
  }
}
