@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

:root {
  --dark-blue: #044265;
  --white-bg: #FFFFFF;
  --bg-color-support-comp: var(--white-bg);
  --support-color-text: var(--dark-blue);
  --support-disabled: #CBCBCB
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* This is to remove up and down arrows from number types*/
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::-webkit-scrollbar-button{
  display: none;
}
::-webkit-scrollbar {
  width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d8d8d8;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:horizontal {
  background: #d8d8d8;
  border-radius: 10px;
  width: 8px;
}
::-webkit-scrollbar:horizontal {
  height: 6px;
}

.login-button {
  height: 40px;
  border-radius: 2px;
  padding: 8px, 16px, 8px, 16px;
}
body {
  background-color: #e6f1f4 !important;
}
.login-div {
  padding: 10px;
  width: 340px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media (max-height: 500px) {
  .login-div {
    height: 300px !important;
  }
}
.onsor-logo {
  width: 320px;
  margin-bottom: 10%;
}
.header {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #1890ff;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 12px 0px;
}
.login-input {
  margin: 12px 0px;
}
.invalid {
  color: red;
  font-size: 10px;
}
.forgot-password-link {
  float: right;
}
.register-form-card {
  width: 900px;
  overflow: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.reset_resetRoot__3EGp4 {
  width: 400px;
  margin: 5% auto 0;
  text-align: center;
}
.reset_inputFieldRoot__3iFgY {
  margin-left: 20px;
  margin-top: 100px;
  display: inline-block;
  width: 352px;
  justify-content: center;
}
.reset_passwordResetTitle__1MCUt {
  font-size: 14px;
  line-height: 22px;
  color: #1890ff;
  text-align: left;
  font-weight: bold;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-color: #1890ff;
          text-decoration-color: #1890ff;
}
.reset_passwordResetExplanation__1GMiR {
  margin-top: 28px;
  text-align: start;
  font-weight: bold;
}
.reset_inputField__26WG0 {
  display: block;
  width: 100%;
  margin: 22px 0 auto;
}
.reset_resetButton__ou76u {
  margin-top: 22px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 16px;
  background: #1890ff;
  border: 1px solid #1890ff;
  box-sizing: border-box;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
  border-radius: 2px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}
.reset_a__1w-RJ {
  display: inline-block;
  margin-top: 22px;
}
.reset_informationLogo__8w1mH {
  margin-top: 145px;
}
.reset_checkYourEmail__3LM7B {
  margin-top: 29px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.85);
}
.reset_checkYourEmailSubtitle__1nuQu {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
}
.reset_checkEmailResetButtons__3tKff {
  text-align: center;
  margin-top: 24px;
}
.reset_checkEmailButton__2aa1K {
  margin-left: 10px;
  margin-right: 10px;
}
.reset_blueButton__2up49 {
  background: #1890ff;
  color: white;
}
.reset_inputDiv__3WfFd {
  text-align: start;
}
.reset_labelError__1ryEp {
  width: 100%;
  display: inline-flex;
  color: red;
}

.steps-div {
  padding: 20px;
  text-align: left;
  overflow: auto;
}
.step-input {
  width: 70%;
}
.upload-logo {
  height: 70% !important;
}

.image-upload-grid .ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: auto;
}
.image-upload-grid .ant-upload-list-picture-card-container {
  width: 100%;
  height: auto;
}

.image-upload-grid .ant-btn-text {
  color: #ffffff;
}

.Marker_marker__38zBv {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  background-color: #000;
  border: 2px solid rgb(185, 32, 32);
  border-radius: 100%;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: rgb(226, 17, 17);
  cursor: pointer;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.Marker_marker__38zBv:hover {
  z-index: 1;
  background-color: rgba(226, 17, 17, 0.178);
}
.Marker_pin__3nQLw {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  position: absolute;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}
.Marker_pin__3nQLw:after {
  content: "";
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  position: absolute;
  border-radius: 50%;
}

.Marker_bounce__1Mtoy {
  -webkit-animation-name: Marker_bounce__1Mtoy;
          animation-name: Marker_bounce__1Mtoy;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.Marker_pulse__QQMvT {
  background: #d6d4d4;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  -webkit-transform: rotateX(55deg);
          transform: rotateX(55deg);
  z-index: -2;
}
.Marker_pulse__QQMvT:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  -webkit-animation: Marker_pulsate__4Rnvb 1s ease-out;
          animation: Marker_pulsate__4Rnvb 1s ease-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px #00cae9;
  -webkit-animation-delay: 1.1s;
          animation-delay: 1.1s;
}

@-webkit-keyframes Marker_pulsate__4Rnvb {
  0% {
    -webkit-transform: scale(0.1, 0.1);
            transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes Marker_pulsate__4Rnvb {
  0% {
    -webkit-transform: scale(0.1, 0.1);
            transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@-webkit-keyframes Marker_bounce__1Mtoy {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px) rotate(-45deg);
            transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateY(30px) rotate(-45deg);
            transform: translateY(30px) rotate(-45deg);
  }

  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
            transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
            transform: translateY(0) rotate(-45deg);
  }
}

@keyframes Marker_bounce__1Mtoy {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px) rotate(-45deg);
            transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateY(30px) rotate(-45deg);
            transform: translateY(30px) rotate(-45deg);
  }

  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
            transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
            transform: translateY(0) rotate(-45deg);
  }
}

.Map_mapContainer__7IlsB {
  width: 49%;
  height: 100%;
}

.Map_mapContainerOTSP__3IUtP {
  width: 100%;
  height: 85%;
}

.Map_searchInput__36N4t {
  width: 40%;
  margin-bottom: 20px;
  background: hsl(0, 0%, 20%);
  color: azure;
  border: 1px solid #222222;
  border-radius: 2px;
  padding: 2px 6px;
  font-size: 14px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAABB0lEQVQ4jcXRMUoEMRgF4PdPHBkLy1XCzMAewcpWKwUbC7FTkL2BF7AVrPYoHkG2tLIZbBfmTwI7N5jM/rFRkDhGWQRfl1d8eSTAf4fGSmausiw7EpE9AC9lWS6IaP0rwBgzAzAB8DgMwyrP88MQwpmIPNR1bZJzrLUn1tqLuG+aZpuZ5yEElQSMMfcJ/LRt2+O4z6Jz/x3gvX9WSh0kASIafVQAyPN8H8AqCYQQBufcZAwIIZyLyCIJFEUxF5G7GGHmSwDrqqo4Br5M7rput+/7WyLaer9gB8CriFwppW601suxhT/GOTdl5ifn3HQj4DNijKk/uvgbk9FaL4no2ns/bLziz/MG2g10bOtP8/8AAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 20px;
  outline: 0;
}

.Map_searchInputOTSP__3LDRM {
  width: 70%;
  margin-bottom: 20px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 4px 11px;
  font-size: 14px;
}

@media screen and (max-width: 800px) {
  .Map_searchInput__36N4t {
    width: 70% !important;
  }
}

.page-header {
  background-color: #094668 !important;
  height: 8vh !important;
  padding: 0 16px 0 16px;
  justify: space-between;
  color: white;
}
.header-content {
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.page-content {
  background-color: #e5e5e5;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  width: 800px;
}
.page-menu {
  height: 92vh;
}

.ant-menu-item-group-title {
  font-size: 10px !important;
}

@media (min-width: 800px) {
  .toggle-button {
    display: none !important;
  }
}

@media (max-width: 414px) {
  .header-content {
    top: 110%;
  }
}

.Ticket_cardInner__S7G5X {
  display: flex;
  justify-content: space-between;
}

.Ticket_cardTitle__3CHU0 {
  color: rgb(140, 140, 140);
  font-size: 16px;
}

.Ticket_value__2QjVW {
  font-family: "Roboto";
  font-weight: bold;
  font-size: 30px;
}

.DashboardComponent_title__NiNUa {
  color: rgba(0, 0, 0, 0.85);
  padding-left: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: white;
}

.TotalCostOfSpareParts_header__2Vwos {
  border-bottom: 1px solid #f0f0f0;
}

.TotalCostOfSpareParts_cardInner__1Cgl8 {
  display: flex;
  justify-content: space-between;
}

.TotalCostOfSpareParts_cardTitle__1C5JX {
  color: rgb(140, 140, 140);
  font-size: 16px;
}

.TotalCostOfSpareParts_value__3Aicq {
  font-family: "Roboto";
  font-weight: bold;
  font-size: 30px;
}

.calender-icon {
  font-size: 22px;
  /* margin-left: 30%; */
  float: right;
}
.calender-icon:hover {
  color: #1890ff;
  cursor: pointer;
}

.ServiceProvider_root__TvrF2 {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  border: 1px solid var(--support-color-text);
  cursor: pointer;
}

.ServiceProvider_root__TvrF2:hover {
  background-color: #07232d;
}

.ServiceProvider_serviceProviderDetailsContainer__ajoHS > div {
  padding-bottom: 15px;
}
.ServiceProvider_textColor__1Mv6K {
  color: #CBCBCB;
  font-weight: 400;
}
.ServiceProvider_profileRoot__36qPa {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
  margin-left: 30px;
  width: 100%;
}
.ServiceProvider_focusedColor__2Yu56 {
  background-color: #07232d;
}
.ServiceProvider_focusedColor__2Yu56 > div,
h3 {
  color: #48bbcb;
}
.ServiceProvider_profileContainer__1OGw5 {
  color: #9999;
  width: 50% !important;
}
.ServiceProvider_boldName__3axFl {
  font-family: "Roboto";
  font-weight: bold;
}
.ServiceProvider_imageContainer__34Fnk {
  display: flex;
  align-items: flex-start;
  position: relative;
}
.ServiceProvider_imageContainer__34Fnk > img {
  margin: auto;
}
.ServiceProvider_textContainer__2tYTY {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.ServiceProvider_schedule__1lIHB {
  display: flex;
  width: 100%;
}
.ServiceProvider_buttonContainer__3WVsW {
  width: 20%;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
}
.ServiceProvider_name__2tr9M {
  width: 30%;
}
.ServiceProvider_day__3yOHP {
  width: 60%;
}
.ServiceProvider_createRequestContainer__CxwkI {
  border: 1px solid #48bbcb;
  background-color: #07232d;
  outline: none;
  cursor: pointer;
}
.ServiceProvider_learnMoreContainer__13WAl {
  border: 1px solid #CBCBCB;
  background-color: #00121c;
  color: #CBCBCB;
  outline: none;
  cursor: pointer;
}
.ServiceProvider_learnMoreContainer__13WAl:hover {
  border-color: #42bbcc;
  color: #42bbcc;
}
.ServiceProvider_createRequestContainer__CxwkI:hover {
  background-color: #42bbcc;
  color: #07232d;
}
@media screen and (max-width: 800px) {
  .ServiceProvider_root__TvrF2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .ServiceProvider_profileContainer__1OGw5 {
    width: auto !important;
  }
  .ServiceProvider_imageContainer__34Fnk {
    display: flex;
    height: 20%;
    width: 100%;
  }
  .ServiceProvider_imageContainer__34Fnk > img {
    margin: auto;
  }
  .ServiceProvider_textContainer__2tYTY {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  .ServiceProvider_schedule__1lIHB {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  .ServiceProvider_buttonContainer__3WVsW {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    width: auto;
  }
  .ServiceProvider_textContainer__2tYTY > div {
    text-align: center;
  }
  .ServiceProvider_learnMoreContainer__13WAl {
    padding: 1em;
  }
}

.ServiceProvider_serviceProviderRow__vefbN {
  cursor: pointer;
}

.ServiceProvider_shopNameColor__3efzp {
  color: #cbcbcb;
}

.title {
  /* Title */
  position: static;
  width: 86px;
  height: 28px;
  left: 0px;
  top: 6px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.filter {
  margin: 3px;
}
.search-input {
  height: 32px;
  left: 8px;
  top: 16px;
  border-radius: 2px;
}

.active {
  color: green;
}

.inactive {
  color: orange;
}

.pending-approval {
  color: blue;
}

.rejected {
  color: red;
}

.breadcrumb-header {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: white;
  color: black;
}
.details-tag {
  margin-left: 10px;
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.row-padding {
  padding: 5px;
}
thead[class*="ant-table-thead"] th {
  font-weight: 700 !important;
}

.ServiceRequest_serviceRequestRow__1I9sD {
  cursor: pointer;
}

.technician_technicianRow__3c24t {
  cursor: pointer;
}

.inventory_inventoryRow__1neuZ {
  cursor: pointer;
}

.title {
  /* Title */
  position: static;
  width: 86px;
  height: 28px;
  left: 0px;
  top: 6px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.filter {
  margin: 3px;
}
.search-input {
  height: 32px;
  left: 8px;
  top: 16px;
  border-radius: 2px;
}
.breadcrumb-header {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: white;
  color: black;
}
.details-tag {
  margin-left: 10px;
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.row-padding {
  padding: 5px;
  padding-bottom: 15px;
}
.steps-header {
  width: 70%;
  margin: auto;
}
thead[class*="ant-table-thead"] th {
  font-weight: 700 !important;
}

.ant-tabs-tab {
  font-weight: normal !important;
  font-size: 16px !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 500 !important;
  font-size: 16px !important;
}
.time-line-container {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-left: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 110px;
  float: right;
  background-image: linear-gradient(180deg, white 70%, rgba(0, 0, 0, 0.027));
}

.time-line-container::-webkit-scrollbar {
  width: 0px;
}
.time-line-container .ant-steps-vertical {
  flex-direction: column-reverse;
}

.time-line .ant-steps-item .ant-steps-item-icon {
  float: right;
}

.time-line .ant-steps-item-container {
  text-align: right;
}

.time-line .ant-steps-item .ant-steps-item-content {
  min-height: 30px;
}

.time-line .ant-steps-item-tail {
  left: unset !important;
  top: unset;
  right: 32px;
  float: right;
  visibility: hidden;
}

.download-button:hover,
.download-button:active,
.download-button:focus {
  color: black;
  border-color: darkgrey;
}

.Support_root__1ODOu {
  display: flex;
  flex-direction: column;
  background-color: #00121c;
  height: 90vh;
}
.Support_rootMargin__zFJpE {
  margin-left: 50px;
  margin-right: 50px;
}
.Support_background__2k013 {
  background-color: #00121c;
  height: 100vh;
}
.Support_title__1NMR_ {
  font-family: "Roboto";
  color: #48bbcb;
  margin: 16px 0px 5px;
  font-size: 34px;
}
.Support_text___4spD {
  font-family: "Roboto";
  color: #cbcbcb;
  margin: 0px 0px 40px;
}
.Support_mapDataContainer__2IPR4 {
  display: flex;
  height: 70%;
  width: 100%;
  justify-content: space-around;
}
.Support_searchContainer__1-J0f {
  height: 8%;
  width: 100%;
  z-index: 1;
}
.Support_dataContainer__3zwlr {
  background-color: #333333;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Support_Centeralize__394SC {
  align-items: center;
  justify-content: center;
}
.Support_noDataImg__3jFah {
  position: absolute;
  top: 50%;
  right: 50%;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
  z-index: 1;
}
.Support_paperImg__2VW_P {
  position: absolute;
  top: 50%;
  right: 50%;
  -webkit-transform: translate(50%, -60%);
          transform: translate(50%, -60%);
}
.Support_thinkingImg__2aWx2 {
  position: absolute;
  top: 50%;
  right: 50%;
  -webkit-transform: translate(60px, -50px);
          transform: translate(60px, -50px);
}
.Support_dotImg__3GpY6 {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background: #bfbfbf;
  position: absolute;
  top: 50%;
  right: 50%;
}
.Support_one__IQv-G {
  -webkit-transform: translate(41px, -42px);
          transform: translate(41px, -42px);
}
.Support_two__xqMQK {
  -webkit-transform: translate(48px, -42px);
          transform: translate(48px, -42px);
}
.Support_three__nmmru {
  -webkit-transform: translate(55px, -42px);
          transform: translate(55px, -42px);
}
.Support_noDataText__3veFu {
  position: absolute;
  top: 60%;
  right: 50%;
  -webkit-transform: translate(50%, 50%);
          transform: translate(50%, 50%);
  font-family: "Roboto";
  color: #999999;
}
.Support_h3__P1AVY {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #000;
  line-height: 0.1em;
  margin: 10px 0 20px;
  height: 5%;
}
.Support_transformText__3Vvm_ {
  -webkit-transform: translate(0, -30px);
          transform: translate(0, -30px);
}
.Support_spContainer__1FCzJ::-webkit-scrollbar {
  width: 5px;
}
.Support_spContainer__1FCzJ {
  width: 100%;
  margin-left: 3px;
  overflow-y: auto;
}
.Support_spContainer__1FCzJ::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.Support_spContainer__1FCzJ::-webkit-scrollbar-thumb {
  background-color: #48bbcb;
  border-radius: 6px;
  border: 1px solid var(--scrollbarBG);
}

@media screen and (max-width: 800px) {
  .Support_rootMargin__zFJpE {
    margin-left: 10px;
    margin-right: 10px;
  }
  .Support_background__2k013 {
    background-color: #00121c;
    height: 120vh;
  }
  .Support_mapDataContainer__2IPR4 {
    display: flex;
    width: 90%;
    height: 110%;
    flex-direction: column;
    align-self: center;
  }
  .Support_root__1ODOu {
    align-items: center;
  }
  .Support_title__1NMR_ {
    margin: 16px 0px 5px;
    text-align: center;
  }
  .Support_text___4spD {
    text-align: center;
    margin: 0px;
  }
  .Support_mapDataContainer__2IPR4 {
    margin-left: 0;
  }
  .Support_mapDataContainer__2IPR4 > div {
    width: 100%;
    height: 40%;
  }
}

@media screen and (max-width: 450px) {
  .Support_background__2k013 {
    height: 130vh;
  }
}
.Support_providerContainer__2IWQT {
  height: 100%;
  width: 50%;
  padding: 0px;
  border-width: 0px;
}

.Support_providerContainerList__1jL4F {
  padding: 1%;
  margin-left: 2%;
  height: 95%;
  overflow-y: scroll;
  scrollbar-color: #00121c #07232d !important;
}

.Support_providerContainerList__1jL4F::-webkit-scrollbar {
  width: 15px;
}

.Support_providerContainerList__1jL4F::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #07232d;
  background: #07232d;
}

.Support_providerContainerList__1jL4F::-webkit-scrollbar-thumb {
  background-color: #00121c;
  box-shadow: inset 0px 0px 3px #07232d;
  min-height: 100px;
}

::-webkit-scrollbar-button {
  background-color: #07232d;
  background-size: 10px 10px;
  background-repeat: no-repeat;
  background-position: center center;
  height: 16px;
  width: 1em;
  box-shadow: inset 1px 1px 2px #07232d;
}

::-webkit-scrollbar-button:end:increment {
  background-image: url(/static/media/arrow_down.85671888.svg);
}

::-webkit-scrollbar-button:start:decrement {
  background-image: url(/static/media/arrow_up.1fbfa8fe.svg);
}

.BlackHeader_root__9Nbgq {
  width: 100%;
  display: flex;
  background-color: #00121c;
  padding: 10px 30px 10px 30px;
  align-items: center;
  border-bottom: 1px solid #48bbcb;
}
.BlackHeader_text__1SwkL {
  margin-left: 1%;
  letter-spacing: 10px;
  color: #cbcbcb;
  font-family: "Roboto";
  font-size: 16px;
}
.BlackHeader_logo__1VEdK {
  width: 15%;
  height: auto;
  cursor: pointer;
}
@media screen and (max-width: 800px) {
  .BlackHeader_logo__1VEdK {
    width: 50%;
  }
}

.Box_root__1h_Wo {
  background-color: var(--bg-color-support-comp);
  min-height: 90vh;
  height: 100%;
}
@media screen and (min-width: 800px) {
  .Box_root__1h_Wo {
    width: 97% !important;
  }
}

#support .ant-steps-item-title {
  color: var(--support-color-text) !important;
}
#support
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon
  .ant-steps-icon {
  color: var(--bg-color-support-comp);
}

#support .ant-steps-item-process .ant-steps-item-container .ant-steps-item-icon {
  background-color: var(--support-color-text) !important;
  border-color: var(--support-color-text);
}

#support .ant-steps-item-wait .ant-steps-item-icon .ant-steps-icon {
  color: var(--support-color-text) !important;
}

#support .ant-steps-item-wait .ant-steps-item-icon {
  border: 1px solid var(--support-color-text);
  background-color: transparent !important;
}

#support .ant-steps-item-finish .ant-steps-item-icon {
  background-color: transparent;
  border-color: var(--support-color-text);
}

#support .anticon svg {
  fill: var(--support-color-text);
}

#support .ant-steps-item-title::after {
  background-color: var(--support-color-text);
}

.First_root1__2NRJ_ {
  display: flex;
  width: 50%;
  height: 100%;
  flex-direction: column;
}
.First_textContainer__CJ2BI {
  color: var(--support-color-text);
  font-family: "Roboto";
  margin-bottom: 4px;
}

.First_textAreaContainer__1FH-Q::after {
  color: #cbcbcb;
}

@media screen and (max-width: 800px) {
  .First_root1__2NRJ_ {
    width: 90%;
  }
}

#summary-root .ant-select-selector {
  border: 1px solid var(--support-color-text) !important;
  background-color: var(--bg-color-support-comp) !important;
  color: var(--bg-color-support-comp);
}

#summary-root .ant-select-selector:hover {
  border: 1px solid var(--support-color-text) !important;
}

#summary-root .ant-select:hover .ant-select-arrow {
  color: var(--support-color-text) !important;
}
#summary-root .ant-select-multiple .ant-select-selection-item {
  background-color: var(--support-color-text);
}

#device-issues_textarea {
  background-color: var(--bg-color-support-comp);
  color: var(--support-color-text);
  border: 1px solid var(--support-color-text) !important;
  outline: none;
}

.ant-select-arrow {
  color: var(--bg-color-support-comp);
}

#issue_type-option {
  background-color: var(--bg-color-support-comp);
  color: var(--support-color-text);
}

#issue_type-option:hover {
  color: var(--bg-color-support-comp);
}

#issue_type-option svg {
  fill: var(--support-color-text) !important;
}

#issue_type-option:hover {
  background-color: var(--support-color-text);
}

#device-issues_textarea:hover {
  border-color: var(--support-color-text) !important;
}

.Second_root2__1AclA {
  display: flex;
  width: 50%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 50px;
}
.Second_imageUploader__2aIWm {
  display: none;
}
.Second_textContainer__1OUGT {
  color: var(--support-color-text);
  font-family: "Roboto";
}
.Second_rowContainer__1oxLo {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}
.Second_disabledInputContainer__3ANwR {
  border: 1px solid var(--support-color-text);
  background-color: var(--support-disabled);
  color: var(--support-color-text);
  padding: 4px 11px;
  margin-bottom: 20px;
}
.Second_inputContainer__fkKUG {
  outline: none;
  background-color: var(--bg-color-support-comp);
  border: 1px solid var(--support-color-text);
  color: var(--support-color-text);
}
.Second_inputContainer__fkKUG::-webkit-input-placeholder {
  color: #cbcbcb;
  opacity: 1;
}
.Second_inputContainer__fkKUG:-ms-input-placeholder {
  color: #cbcbcb;
  opacity: 1;
}
.Second_inputContainer__fkKUG::placeholder {
  color: #cbcbcb;
  opacity: 1;
}
.Second_inputContainer__fkKUG[disabled] {
  border: 1px solid var(--support-color-text);
  background-color: var(--support-disabled);
  color: var(--support-color-text);
}
.Second_inputContainer__fkKUG[disabled]:hover {
  border: 1px solid var(--support-color-text);
  background-color: var(--support-disabled);
  color: var(--support-color-text);
}
.Second_serialNumberContainer__2WUeY {
  margin-bottom: 10px;
}
.Second_buttonContainer__13toi {
  border: 1px solid var(--support-color-text);
  background-color: var(--support-color-text);
  cursor: pointer;
  outline: none;
  margin-bottom: 5px;
  color: var(--bg-color-support-comp);
}

.Second_buttonContainer__13toi:active {
  border: 1px solid var(--support-color-text);
  background-color: #404040;
  color: white;
}
.Second_buttonContainer__13toi[disabled] {
  border: 1px solid #757575;
  color: #757575;
}
.Second_buttonContainer__13toi[disabled]:hover {
  border: 1px solid #757575;
  color: #757575;
}
.Second_buttonContainer__13toi:hover {
  border: 1px solid var(--support-color-text);
  background-color: var(--support-color-text);
  color: var(--bg-color-support-comp);
}
.Second_buttonContainerConfirmed__3xDzt[disabled] {
  border: 1px solid var(--support-color-text);
}
.Second_buttonContainerConfirmed__3xDzt[disabled]:hover {
  border: 1px solid var(--support-color-text);
}
.Second_uploadContainer__tOpbr {
  border: 1px dotted #757575;
  display: flex;
  padding: 5px;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
}
.Second_uploadContainer__tOpbr:active {
  border: 1px dotted #616060;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Second_uploadContainer__tOpbr:active > div {
  color: #616060;
  font-family: "Roboto";
}
.Second_uploadContainer__tOpbr > div {
  color: #757575;
  font-family: "Roboto";
  padding: 7px 18px 7px 18px;
}
.Second_antUpload___Ho9N span {
  color: #757575 !important;
  background-color: var(--bg-color-support-comp) !important;
  border-color: #757575 !important;
}
.Second_ant-upload__YsoRG.Second_ant-upload-select-picture-card__1riUp {
  background-color: #404040 !important;
}
@media screen and (max-width: 800px) {
  .Second_root2__1AclA {
    width: 90%;
  }
}

#second .ant-upload.ant-upload-select-picture-card {
  width: 100%;
  background-color: transparent !important;
}

.Third_root3__3R1rm {
  display: flex;
  width: 50%;
  height: 100%;
  margin: 2%;
  flex-direction: column;
}
.Third_textContainer__1QZGr {
  color: var(--support-color-text);
  font-family: "Roboto";
}
.Third_inputContainer__1Tv3j {
  outline: none;
  background-color: var(--bg-color-support-comp);
  border: 1px solid #cbcbcb;
  color: var(--support-color-text);
  margin-bottom: 2px;
}
.Third_inputContainer__1Tv3j:hover {
  border-color: #42bbcc !important;
}
.Third_inputContainer__1Tv3j::-webkit-input-placeholder {
  color: #cbcbcb;
  opacity: 1;
}
.Third_inputContainer__1Tv3j:-ms-input-placeholder {
  color: #cbcbcb;
  opacity: 1;
}
.Third_inputContainer__1Tv3j::placeholder {
  color: #cbcbcb;
  opacity: 1;
}
.Third_ii__2nkCU {
  height: 10px;
}
@media screen and (max-width: 800px) {
  .Third_root3__3R1rm {
    width: 90%;
  }
}

#phone-input .react-tel-input .special-label {
  color: #cbcbcb !important;
}

.thirdStepPhoneNumber .ant-input-group-addon {
  color: var(--support-color-text);
  background: var(--bg-color-support-comp);
}

.thirdStepPhoneNumber input {
  background: var(--bg-color-support-comp);
  color: var(--support-color-text);
}

.thirdStepPhoneNumber .ant-input-group-addon {
  color: var(--support-color-text);
  background-color: var(--bg-color-support-comp);
}
.thirdStepPhoneNumber input:hover {
  border-color: var(--support-color-text) !important;
}

.Summary_root__qQp31 {
  overflow: hidden;
  text-align: center;
}
.Summary_section__2yXgd {
  border: 1px solid var(--support-color-text);
  width: 90%;
  flex-direction: column;
  justify-content: space-between;
  padding: 2%;
  margin: 2%;
  display: inline-block;
  text-align: left;
}
.Summary_divTitle__2otUG {
  color: var(--support-color-text);
  font-size: 18px;
  font-weight: normal;
}
.Summary_divTitle__2otUG span {
  float: right;
  font-size: 14px;
  font-weight: normal;
  text-decoration: underline;
  cursor: pointer;
}

.Summary_textContainer__2CnBP {
  color: var(--support-color-text);
  font-family: "Roboto";
}
.Summary_container__14aX1 {
  overflow: auto;
}

.Summary_rightCol__3Z3nj {
  margin-left: 4%;
}

.Summary_leftCol__3rywI {
  font-weight: bold;
}

.Button_button__1NA0f {
  background-color: var(--support-color-text);
  color: var(--bg-color-support-comp);
  outline: none;
  box-shadow: none;
  border: 1px solid var(--support-color-text);
  width: 64px;
  height: 32px;
}
.Button_button__1NA0f:active {
  background-color: var(--support-color-text);
  border: 1px solid var(--support-color-text);
}
.Button_backBtn__1i-6c {
  background-color: var(--bg-color-support-comp);
  color: var(--support-color-text);
  outline: none;
  box-shadow: none;
  border: 1px solid var(--support-color-text);
}
.Button_backBtn__1i-6c:active {
  background-color: #353535;
  color: #646464;
  outline: none;
  box-shadow: none;
  border: 1px solid #646464;
}

.Stepper_root__3bOkl {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.Stepper_finish__IVlv9 {
  text-align: center;
  color: #757575;
  margin: auto;
  padding: 100px;
}
.Stepper_button__3Uk0j {
  padding: "4px 15px";
  background-color: #42bbcc;
  color: black;
  outline: none;
  box-shadow: none;
  border: 1px solid #42bbcc;
  cursor: pointer;
}
.Stepper_buttonContainer__1mteV {
  height: 10%;
  position: relative;
}
@media screen and (max-width: 1024px) {
  /* laptop + tablet */
  .Stepper_root__3bOkl {
    height: 80%;
  }
}

@media screen and (max-width: 800px) {
  .Stepper_buttonContainer__1mteV {
    margin: 75px;
  }
  .Stepper_step__2rzV7 {
    margin: 8px 0px;
  }
  .Stepper_finish__IVlv9 {
    height: 290px;
  }
}

.CustomerTicket_root__tXSw2 {
  display: flex;
  padding: 15px;
  justify-content: center;
  background-color: #00121c;
  height: 100%;
}
.CustomerTicket_leftBox__cfqfJ {
  flex: 2 1;
}
.CustomerTicket_rightBox__1QZaQ {
  flex: 1 1;
}

@media screen and (max-width: 800px) {
  .CustomerTicket_root__tXSw2 {
    overflow: auto;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
  }
  .CustomerTicket_rightBox__1QZaQ {
    margin: 20px 0px 20px 0px;
  }
}

