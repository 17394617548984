@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.title {
  /* Title */
  position: static;
  width: 86px;
  height: 28px;
  left: 0px;
  top: 6px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.filter {
  margin: 3px;
}
.search-input {
  height: 32px;
  left: 8px;
  top: 16px;
  border-radius: 2px;
}
.breadcrumb-header {
  height: fit-content;
  background-color: white;
  color: black;
}
.details-tag {
  margin-left: 10px;
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.row-padding {
  padding: 5px;
  padding-bottom: 15px;
}
.steps-header {
  width: 70%;
  margin: auto;
}
thead[class*="ant-table-thead"] th {
  font-weight: 700 !important;
}

.ant-tabs-tab {
  font-weight: normal !important;
  font-size: 16px !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 500 !important;
  font-size: 16px !important;
}
.time-line-container {
  width: fit-content;
  padding-left: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 110px;
  float: right;
  background-image: linear-gradient(180deg, white 70%, rgba(0, 0, 0, 0.027));
}

.time-line-container::-webkit-scrollbar {
  width: 0px;
}
.time-line-container .ant-steps-vertical {
  flex-direction: column-reverse;
}

.time-line .ant-steps-item .ant-steps-item-icon {
  float: right;
}

.time-line .ant-steps-item-container {
  text-align: right;
}

.time-line .ant-steps-item .ant-steps-item-content {
  min-height: 30px;
}

.time-line .ant-steps-item-tail {
  left: unset !important;
  top: unset;
  right: 32px;
  float: right;
  visibility: hidden;
}

.download-button:hover,
.download-button:active,
.download-button:focus {
  color: black;
  border-color: darkgrey;
}
