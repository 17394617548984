.page-header {
  background-color: #094668 !important;
  height: 8vh !important;
  padding: 0 16px 0 16px;
  justify: space-between;
  color: white;
}
.header-content {
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.page-content {
  background-color: #e5e5e5;
  min-width: fit-content;
  width: 800px;
}
.page-menu {
  height: 92vh;
}

.ant-menu-item-group-title {
  font-size: 10px !important;
}

@media (min-width: 800px) {
  .toggle-button {
    display: none !important;
  }
}

@media (max-width: 414px) {
  .header-content {
    top: 110%;
  }
}
