@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
.root2 {
  display: flex;
  width: 50%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 50px;
}
.imageUploader {
  display: none;
}
.textContainer {
  color: var(--support-color-text);
  font-family: "Roboto";
}
.rowContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}
.disabledInputContainer {
  border: 1px solid var(--support-color-text);
  background-color: var(--support-disabled);
  color: var(--support-color-text);
  padding: 4px 11px;
  margin-bottom: 20px;
}
.inputContainer {
  outline: none;
  background-color: var(--bg-color-support-comp);
  border: 1px solid var(--support-color-text);
  color: var(--support-color-text);
}
.inputContainer::placeholder {
  color: #cbcbcb;
  opacity: 1;
}
.inputContainer[disabled] {
  border: 1px solid var(--support-color-text);
  background-color: var(--support-disabled);
  color: var(--support-color-text);
}
.inputContainer[disabled]:hover {
  border: 1px solid var(--support-color-text);
  background-color: var(--support-disabled);
  color: var(--support-color-text);
}
.serialNumberContainer {
  margin-bottom: 10px;
}
.buttonContainer {
  border: 1px solid var(--support-color-text);
  background-color: var(--support-color-text);
  cursor: pointer;
  outline: none;
  margin-bottom: 5px;
  color: var(--bg-color-support-comp);
}

.buttonContainer:active {
  border: 1px solid var(--support-color-text);
  background-color: #404040;
  color: white;
}
.buttonContainer[disabled] {
  border: 1px solid #757575;
  color: #757575;
}
.buttonContainer[disabled]:hover {
  border: 1px solid #757575;
  color: #757575;
}
.buttonContainer:hover {
  border: 1px solid var(--support-color-text);
  background-color: var(--support-color-text);
  color: var(--bg-color-support-comp);
}
.buttonContainerConfirmed[disabled] {
  border: 1px solid var(--support-color-text);
}
.buttonContainerConfirmed[disabled]:hover {
  border: 1px solid var(--support-color-text);
}
.uploadContainer {
  border: 1px dotted #757575;
  display: flex;
  padding: 5px;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
}
.uploadContainer:active {
  border: 1px dotted #616060;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.uploadContainer:active > div {
  color: #616060;
  font-family: "Roboto";
}
.uploadContainer > div {
  color: #757575;
  font-family: "Roboto";
  padding: 7px 18px 7px 18px;
}
.antUpload span {
  color: #757575 !important;
  background-color: var(--bg-color-support-comp) !important;
  border-color: #757575 !important;
}
.ant-upload.ant-upload-select-picture-card {
  background-color: #404040 !important;
}
@media screen and (max-width: 800px) {
  .root2 {
    width: 90%;
  }
}
