@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
.root1 {
  display: flex;
  width: 50%;
  height: 100%;
  flex-direction: column;
}
.textContainer {
  color: var(--support-color-text);
  font-family: "Roboto";
  margin-bottom: 4px;
}

.textAreaContainer::after {
  color: #cbcbcb;
}

@media screen and (max-width: 800px) {
  .root1 {
    width: 90%;
  }
}
