.steps-div {
  padding: 20px;
  text-align: left;
  overflow: auto;
}
.step-input {
  width: 70%;
}
.upload-logo {
  height: 70% !important;
}

.image-upload-grid .ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: auto;
}
.image-upload-grid .ant-upload-list-picture-card-container {
  width: 100%;
  height: auto;
}

.image-upload-grid .ant-btn-text {
  color: #ffffff;
}
