.root {
  background-color: var(--bg-color-support-comp);
  min-height: 90vh;
  height: 100%;
}
@media screen and (min-width: 800px) {
  .root {
    width: 97% !important;
  }
}
