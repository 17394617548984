@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
.root {
  overflow: hidden;
  text-align: center;
}
.section {
  border: 1px solid var(--support-color-text);
  width: 90%;
  flex-direction: column;
  justify-content: space-between;
  padding: 2%;
  margin: 2%;
  display: inline-block;
  text-align: left;
}
.divTitle {
  color: var(--support-color-text);
  font-size: 18px;
  font-weight: normal;
}
.divTitle span {
  float: right;
  font-size: 14px;
  font-weight: normal;
  text-decoration: underline;
  cursor: pointer;
}

.textContainer {
  color: var(--support-color-text);
  font-family: "Roboto";
}
.container {
  overflow: auto;
}

.rightCol {
  margin-left: 4%;
}

.leftCol {
  font-weight: bold;
}
