.title {
  color: rgba(0, 0, 0, 0.85);
  padding-left: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: white;
}
