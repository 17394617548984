@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.title {
  /* Title */
  position: static;
  width: 86px;
  height: 28px;
  left: 0px;
  top: 6px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.filter {
  margin: 3px;
}
.search-input {
  height: 32px;
  left: 8px;
  top: 16px;
  border-radius: 2px;
}

.active {
  color: green;
}

.inactive {
  color: orange;
}

.pending-approval {
  color: blue;
}

.rejected {
  color: red;
}

.breadcrumb-header {
  height: fit-content;
  background-color: white;
  color: black;
}
.details-tag {
  margin-left: 10px;
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.row-padding {
  padding: 5px;
}
thead[class*="ant-table-thead"] th {
  font-weight: 700 !important;
}
