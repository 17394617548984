#support .ant-steps-item-title {
  color: var(--support-color-text) !important;
}
#support
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon
  .ant-steps-icon {
  color: var(--bg-color-support-comp);
}

#support .ant-steps-item-process .ant-steps-item-container .ant-steps-item-icon {
  background-color: var(--support-color-text) !important;
  border-color: var(--support-color-text);
}

#support .ant-steps-item-wait .ant-steps-item-icon .ant-steps-icon {
  color: var(--support-color-text) !important;
}

#support .ant-steps-item-wait .ant-steps-item-icon {
  border: 1px solid var(--support-color-text);
  background-color: transparent !important;
}

#support .ant-steps-item-finish .ant-steps-item-icon {
  background-color: transparent;
  border-color: var(--support-color-text);
}

#support .anticon svg {
  fill: var(--support-color-text);
}

#support .ant-steps-item-title::after {
  background-color: var(--support-color-text);
}
