@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
.root3 {
  display: flex;
  width: 50%;
  height: 100%;
  margin: 2%;
  flex-direction: column;
}
.textContainer {
  color: var(--support-color-text);
  font-family: "Roboto";
}
.inputContainer {
  outline: none;
  background-color: var(--bg-color-support-comp);
  border: 1px solid #cbcbcb;
  color: var(--support-color-text);
  margin-bottom: 2px;
}
.inputContainer:hover {
  border-color: #42bbcc !important;
}
.inputContainer::placeholder {
  color: #cbcbcb;
  opacity: 1;
}
.ii {
  height: 10px;
}
@media screen and (max-width: 800px) {
  .root3 {
    width: 90%;
  }
}
